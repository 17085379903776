<script setup>
import { defineProps, ref, computed } from "vue";
import { useStore } from "vuex";
import router from "./../router";
import { financial } from "./../plugin/GlobalFuctions";
const props = defineProps(["product"]);
const store = useStore();
const primaryColor = props.product.primary;
const whiteColor = props.product.whiteColor;
let product = props.product.data;
let qty = 1;
const fab = computed(() => { return (store.getters.wishListData.find(element => {return element.id === props.product.data.id})) !== undefined ? true : props.product.fab});
const addToCart = (add) => {
  let json = JSON.stringify(add);
  let token = store.getters.isLoggedIn;
  store.dispatch("getAddToCart",{token, json, qty}).then(() => {
    store.dispatch("updateCartFromLocalStorage", token);
    deleteToWishlist(add);
  })
}
const deleteToWishlist = (data) => {
  let token = store.getters.isLoggedIn;
  store.dispatch("getUserData", token).then(() => {
    let productId = data.id;
    if(productId > 0) {
      store.dispatch("getWishlistsDelete", {token, productId}).then(() => {
        store.dispatch("getWishlistsShow");
      });
    }
  });
}
</script>
<template>
  <div class="product bg-white-color p-3">
    <button class="fabBtn" @click="addToCart(props.product.data)"><i class="bi bi-box-arrow-in-up"></i></button>
    <router-link :to="`/product/${props.product.data.slug}`">
      <div class="thumbnail-img mb-3">
        <img
          v-if="props.product.data.thumbnail_image"
          :src="props.product.data.thumbnail_image"
          :alt="props.product.data.name"
        />
        <img v-else src="./../assets/images/product.png" alt="Product Image"/>
      </div>
    </router-link>
    <div
      class="save"
      v-if="props.product.data.base_price - props.product.data.base_discounted_price > 0"
    >
      Save ${{financial(props.product.data.base_price - props.product.data.base_discounted_price)}}
    </div>
    <div
      class="down"
      v-if="props.product.data.base_price - props.product.data.base_discounted_price > 0"
    ></div>
    <div class="row mb-1 g-0">
      <div class="col-auto saleUsd me-2">
        ${{ financial(props.product.data.base_discounted_price) }}
      </div>
      <div class="col-auto mainUsd text-decoration-line-through" v-if="props.product.data.base_price - props.product.data.base_discounted_price > 0">${{ financial(props.product.data.base_price) }}</div>
    </div>
    <div class="row g-0">
      <div class="col-auto saleUsd me-2">
        CF{{ financial(props.product.data.base_discounted_price * 656) }}
      </div>
      <div class="col-auto mainUsd text-decoration-line-through" v-if="props.product.data.base_price - props.product.data.base_discounted_price > 0">
        CF{{ financial(props.product.data.base_price * 656) }}
      </div>
    </div>
    <div class="product-title lh-sm">
      <router-link :to="`/product/${props.product.data.slug}`">{{props.product.data.name}}</router-link>
    </div>
    <vue3-star-ratings
      v-model="props.product.data.rating"
      :starSize="12"
      :showControl="false"
      :starColor="primaryColor"
      inactiveColor="#E5E5E5"
      :disableClick="true"
    />
    <p class="ratingTotal" v-if="props.product.data.rating > 0">
      ({{ props.product.data.rating_total }})
    </p>
    <!-- <button type="button" class="btn btn-outline-cart rounded-pill my-2 w-100" @click="addToCart(props.product.data)">
      <i class="bi bi-cart"></i> Add To Cart
    </button> -->
  </div>
</template>
<style lang="scss" scoped>
.product {
  display: block;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  background-color: v-bind(primaryColor);
  position: relative;
  &:hover {
    border-radius: 9px;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(176, 176, 176, 0.67);
    -moz-box-shadow: 0px 0px 25px 0px rgba(176, 176, 176, 0.67);
    box-shadow: 0px 0px 25px 0px rgba(176, 176, 176, 0.67);
    z-index: 99999999;
  }
  &:focus {
    border-radius: 9px;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  &:active {
    border-radius: 9px;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .thumbnail-img {
    height: 180px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .save {
    display: inline-block;
    padding: 4px 8px;
    background-color: v-bind(primaryColor);
    color: #ffffff;
    font-size: 10px;
    margin: 0;
  }
  .down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: v-bind(primaryColor) transparent transparent transparent;
    margin-left: 5px;
  }
  .saleUsd {
    color: v-bind(primaryColor);
    font-size: 14px;
    font-weight: bold;
  }
  .mainUsd {
    font-size: 14px;
    color: #8b918b;
  }
  .product-title {
    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
      color: #3c3c3c;
      display: block;
      margin-top: 5px;
      overflow: hidden;
      // width:100px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  button.fabBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: v-bind(whiteColor);
    border: 0;
    font-size: 1.2rem;
    color: v-bind(primaryColor);
    z-index: 99;
    // height: 40px;
    // width: 40px;
    padding: 10px;
    border-radius: 50px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(176, 176, 176, 0.67);
    -moz-box-shadow: 0px 0px 15px 0px rgba(176, 176, 176, 0.67);
    box-shadow: 0px 0px 15px 0px rgba(176, 176, 176, 0.67);
    i {
      display: flex;
    }
  }
}
.ratingTotal {
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
  font-weight: 400;
}
.btn-outline-cart {
  border: 2px solid v-bind(primaryColor);
  font-size: 14px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(189, 189, 189, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(189, 189, 189, 0.75);
  box-shadow: 0px 0px 2px 0px rgba(189, 189, 189, 0.75);
  &:hover {
    border: 2px solid v-bind(primaryColor);
    color: v-bind(whiteColor);
    background-color: v-bind(primaryColor);
  }
}
</style>
<style lang="scss">
.vue3-star-ratings__wrapper {
  display: inline-block !important;
  padding: 0px !important;
  margin: 5px auto !important;
  .vue3-star-ratings {
    .stars {
      margin: 0 !important;
    }
  }
}
</style>
