<script setup>
import { ref, onMounted } from "vue";
import { useMeta } from "vue-meta";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const store = useStore();
const primary = ref(store.getters.primaryColor);
const whiteColor = ref(store.getters.whiteColor);
const token = store.getters.isLoggedIn;
onMounted(() => {
  store.dispatch("updateCartFromLocalStorage", token);
  store.dispatch("getWishlistsShow", token);
})
useMeta({
  title: "",
  htmlAttrs: { lang: "en", amp: true },
});
</script>
<template>
  <metainfo>
    <template v-slot:title="{ content }">{{content ? `${content} | moncongoexpress.com` : `moncongoexpress.com`}}</template>
  </metainfo>
  <the-header :router="router" :route="route"/>
  <router-view  />
  <the-footer />
</template>
<style lang="scss">
.primary {
  background-color: v-bind(primary) !important;
}
.primary-text {
  color: v-bind(primary) !important;
}
.bg-white-color {
  background-color: v-bind(whiteColor) !important;
}
.white-text {
  color: v-bind(whiteColor) !important;
}
.btn-outline-primary {
  --bs-btn-color: v-bind(primary) !important;
  --bs-btn-border-color: v-bind(primary) !important;
  --bs-btn-hover-bg: v-bind(primary) !important;
  --bs-btn-hover-border-color: v-bind(primary) !important;
  --bs-btn-active-bg: v-bind(primary) !important;
  --bs-btn-active-border-color: v-bind(primary) !important;
  --bs-btn-disabled-color: v-bind(primary) !important;
  --bs-btn-disabled-border-color: v-bind(primary) !important;
}
</style>
