<script setup>
import { ref, computed, defineProps } from "vue";
import { useStore } from "vuex";
import router from "./../router";
import { financial } from "./../plugin/GlobalFuctions";
const store = useStore();
const props = defineProps(["itemData"]);
const token = store.getters.isLoggedIn;
const removeFromCart = () => {
  let shopAccessToken = localStorage.getItem("shopAccessToken");
  let json = JSON.stringify({"cart_id": props.itemData.cart_id, "temp_user_id": shopAccessToken});
  store.dispatch("getDeleteFromCart", {json, token}).then(() => {
    store.dispatch("updateCartFromLocalStorage", token);
  })
}
const onUpdate = (newValue, oldValue) => {
  let shopAccessToken = localStorage.getItem("shopAccessToken");
  if(newValue > props.itemData.qty) {
    let json = JSON.stringify({"type": "plus", "cart_id": props.itemData.cart_id, "temp_user_id": shopAccessToken});
    store.dispatch("getAddQuantity", {json, token}).then(() => {
      store.dispatch("updateCartFromLocalStorage", token);
    })
  } else if (newValue < props.itemData.qty) {
    let json = JSON.stringify({"type": "minus", "cart_id": props.itemData.cart_id, "temp_user_id": shopAccessToken});
    store.dispatch("getAddQuantity", {json, token}).then(() => {
      store.dispatch("updateCartFromLocalStorage", token);
    })
  }
};
const addToWishlist = (data) => {
  store.dispatch("getUserData", token).then(() => {
    let productId = data.product_id;
    if(productId > 0) {
      let json = JSON.stringify({"product_id": productId});
      store.dispatch("getWishlistsAdd", json).then(() => {
        store.dispatch("getWishlistsShow");
        removeFromCart();
      });
    }
  });
}
</script>
<template>
  <div class="card shop-cart mb-3">
    <div class="row g-0">
      <div class="col-md-2 d-flex align-items-center">
        <div class="thumbnail-img my-3">
          <img
            v-if="props.itemData.thumbnail"
            :src="props.itemData.thumbnail"
            :alt="props.itemData.name"
          />
          <img
            v-else
            src="./../assets/images/product.png"
            alt="Product Image"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="card-body d-flex align-items-center h-100">
          <h5 class="card-title fw-bold pe-5">{{ props.itemData.name }}<p class="card-text fw-bold">${{ financial(props.itemData.dicounted_price) }}</p></h5>
          
          <!-- <p class="card-text">
                  <small class="text-muted">Last updated 3 mins ago</small>
                </p> @update:model-value="onUpdate"-->
        </div>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <vue-number-input
          class="w-75"
          size="small"
          v-model="props.itemData.qty"
          @update:model-value="onUpdate"
          :min="1"
          :max="props.itemData.max_qty"
          rounded
          inline
          center
          controls
        ></vue-number-input>
      </div>
      <div class="col-md-2 d-flex align-items-center justify-content-center">
        <button class="btn btn-outline-dark" type="submit" @click="addToWishlist(props.itemData)"><i class="bi bi-box-arrow-in-down"></i></button>
        <button
          class="btn btn-outline-dark ms-2"
          type="submit"
          @click="removeFromCart"
        >
          <i class="bi bi-trash3"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.shop-cart {
  .thumbnail-img {
    height: 100px;
    width: 100px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  // .fs-7 {
  //   font-size: ;
  // }
}
</style>
