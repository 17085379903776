import AuthService from "./../server/AuthService.js";
import Axios from "axios";
import createPersistedState from "vuex-persistedstate";
import Swal from 'sweetalert2';
const getDefaultState = () => {
  return {
    register: {},
    loginData: {},
    token: "",
    user: {},
    userData: null,
    resendPassword: "",
    resetData: {},
    userUpdate: {}
  };
};
const LoginPageStore = {
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    register: (state) => {
      return state.register;
    },
    loginData: (state) => {
      return state.loginData;
    },
    isLoggedIn: (state) => {
      return state.token ? state.token : localStorage.getItem("token");
    },
    getUser: (state) => {
      return state.user;
    },
    userData: (state) => {
      return state.userData !== null ? state.userData.user : "";
    },
    userUpdate: (state) => {
      return state.userUpdate;
    },
    resendPassword: (state) => {
      return state.resendPassword;
    },
    resetData: (state) => {
      return state.resetData;
    }
  },
  mutations: {
    RIGISTER: (state, response) => {
      state.register = response;
    },
    OTP: (state, response) => {
      state.loginData = response;
    },
    LOGIN: (state, response) => {
      state.loginData = response;
    },
    RESENDCODE: (state, response) => {
      state.resendPassword = response;
    },
    RESRTPASSWORD: (state, response) => {
      state.resetData = response;
    },
    USER: (state, response) => {
      state.userData = response;
    },
    USERUPDATE: (state, response) => {
      state.userUpdate = response;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_TOKEN_EMPTY: (state) => {
      state.token = "";
    },
    // SET_USER: (state, user) => {
    //   state.user = user;
    // },
    RESET: (state) => {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    Register: async ({ commit }, json) => {
      const response = await AuthService.register(json);
      commit("RIGISTER", response);
    },
    OtpAction: async ({ commit }, json) => {
      const response = await AuthService.otp(json);
      commit("LOGIN", response);
    },
    Login: async ({ commit }, json) => {
      const response = await AuthService.loginData(json);
      commit("LOGIN", response);
    },
    Authorization: ({ commit, dispatch }, { token, user }) => {
      localStorage.setItem("token", token);
      commit("SET_TOKEN", token);
      // commit("SET_USER", user);
      // set auth header
      Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    getUserData: async ({ commit }, token) => {
      const response = await AuthService.userData(token);
      commit("USER", response);
    },
    getUserUpdate: async ({ commit }, json) => {
      const response = await AuthService.userUpdate(json);
      console.log(response);
      commit("USER", response);
      if(response.success == true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
      }
      // commit("USERUPDATE", response);
    },
    // getLogout: async ({ commit }) => {
    //   localStorage.removeItem("token");
    //   let response = {"data": []};
    //   commit("RESET", "");
    //   commit("WISHLISTCOUNT", response);
    // },
    getResendCode : async ({ commit }, json) => {
      const response = await AuthService.resend_code(json);
      commit("RESENDCODE", response);
    },
    getResetPassword : async ({ commit }, json) => {
      const response = await AuthService.reset(json);
      commit("RESRTPASSWORD", response);
    },
    getReSendOTP: async ({ commit }, json) => {
      const response = await AuthService.reSandOtp(json);
    }
  },
};

export default LoginPageStore;
