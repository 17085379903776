<script setup>
import { ref, computed, defineProps } from "vue";
import { useStore } from "vuex";
import router from "./../router";
import { financial } from "./../plugin/GlobalFuctions";
const store = useStore();
const props = defineProps(["itemData","order"]);
const token = store.getters.isLoggedIn;
</script>
<template>
  <div class="card border-0 shop-cart mb-3">
    <div class="row g-0">
      <div class="col-md-3 d-flex align-items-center">
        <div class="thumbnail-img my-3">
          <img
            v-if="props.itemData.thumbnail"
            :src="props.itemData.thumbnail"
            :alt="props.itemData.name"
          />
          <img
            v-else
            src="./../assets/images/product.png"
            alt="Product Image"
          />
        </div>
      </div>
      <div class="col-md-9 d-flex align-items-center">
        <div class="card-body">
          <h6 class="card-title fw-bold">{{ props.itemData.name }}<span v-if="props.order.length - 1 > 0"> +{{props.order.length - 1}}More</span></h6>
          
          <!-- <p class="card-text">
                  <small class="text-muted">Last updated 3 mins ago</small>
                </p> @update:model-value="onUpdate"-->
        </div>
      </div>
      <!-- <div class="col-md-2 d-flex align-items-center">
        <p class="card-text fw-bold">
            Quantity - {{ props.itemData.quantity }}
          </p>
      </div> -->
      <!-- <div class="col-md-2 d-flex align-items-center justify-content-center">
        <p class="card-text fw-bold">
            ${{ financial(props.itemData.total) }}
          </p>
      </div> -->
    </div>
  </div>
</template>
<style lang="scss" scoped>
.shop-cart {
  .thumbnail-img {
    height: 100px;
    width: 100px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  // .fs-7 {
  //   font-size: ;
  // }
}
</style>
