import axios from "axios";
import URL_API from "./BaseUrl";
import store from "./../store";
import { setupCache } from "axios-cache-adapter";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const api = axios.create({
  adapter: cache.adapter,
});
const AuthService = {
  register(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json" 
     }
    return api({
      url: `${URL_API}auth/signup`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  otp(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json" 
     }
    return api({
      url: `${URL_API}auth/verify`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  loginData(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json" 
    }
    return api({
      url: `${URL_API}auth/login`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  resend_code(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json" 
    }
    return api({
      url: `${URL_API}auth/resend-code`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  reset(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json" 
    }
    return api({
      url: `${URL_API}auth/password/reset`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  userData(token) {
    return api({
      url: `${URL_API}user/info`,
      method: "GET",
      clearCacheEntry: false,
      headers: {"Authorization" : `Bearer ${token}`}
    }).then(async (response) => {
      return response.data;
    });
  },
  userUpdate(json) {
    console.log(json)
    const token = store.getters.isLoggedIn;
    let bodyFormData = new FormData();
    if(json.avatar !== "") {
      const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
          u8arr[n - 1] = bstr.charCodeAt(n - 1)
          n -= 1 // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime })
      }
      let avatar = dataURLtoFile(json.avatar);
      console.log(avatar)
      bodyFormData.append('avatar', avatar, avatar.name);
    }
    bodyFormData.append('name', json.name);
    bodyFormData.append('email', json.email);
    bodyFormData.append('phone', json.phone);
    bodyFormData.append('invalidPhone', json.invalidPhone);
    bodyFormData.append('oldPassword', json.oldPassword);
    bodyFormData.append('password', json.password);
    bodyFormData.append('confirmPassword', json.confirmPassword);
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": `multipart/form-data`,
      "Authorization" : `Bearer ${token}`
    }
    return axios({
      url: `${URL_API}user/info/update`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: bodyFormData
    }).then(async (response) => {
      return response.data;
    });
  },
  reSandOtp(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json" 
     }
    return api({
      url: `${URL_API}auth/password/reset`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  loadRating(id) {
    return axios({
      url: `${URL_API}user/review/check/${id}`,
      method: "GET",
      clearCacheEntry: false
    }).then(async (response) => {
      return response.data;
    });
  },
  addRating(json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json" 
     }
    return axios({
      url: `${URL_API}user/review/submit`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
};

export default AuthService;
