import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
config.autoAddCss = false;
library.add(fas, far);
import "@fortawesome/fontawesome-svg-core/styles.css";
import TheHeader from "./../components/TheHeader.vue";
import TheFooter from "./../components/TheFooter.vue";
import HomeTitle from "./../components/HomeTitle.vue";
import BannerTempOne from "./../components/banner/BannerTempOne.vue";
import BannerTempTwo from "./../components/banner/BannerTempTwo.vue";
import BannerTempThree from "./../components/banner/BannerTempThree.vue";
import ProductTemp from "./../components/ProductTemplate.vue";
import SavedForLater from "./../components/SavedForLater.vue";
import ProductSideTemplate from "./../components/ProductSideTemplate.vue";
import CategoriesTemp from "./../components/CategoriesTemplate.vue";
import CategoriesListTemp from "./../components/CategoryListTemplate.vue";
import ProductRecentlyTemp from "./../components/ProductRecently.vue";
import TheBootCumTemp from "./../components/TheBootCumTemp.vue";
import customCheckbox from "./../components/custom-checkbox.vue";
import vue3StarRatings from "vue3-star-ratings";
import VOtpInput from "vue3-otp-input";
import Paginate from "vuejs-paginate-next";
import VerticalGallery from './../components/VerticalGallery.vue';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import MyAccountNav from './../components/MyAccountNav.vue';
import MyAccountSide from './../components/MyAccountSide.vue';
import ProductCheckout from './../components/ProductCheckout.vue';
import ProductOrder from './../components/ProductOrder.vue';
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
// import VueBase64FileUpload from 'vue-base64-file-upload';
export default {
  install: (app, options) => {
    app.component("font-awesome-icon", FontAwesomeIcon);
    app.component("the-header", TheHeader);
    app.component("the-footer", TheFooter);
    app.component("home-title", HomeTitle);
    app.component("banner-tempone", BannerTempOne);
    app.component("banner-temptwo", BannerTempTwo);
    app.component("banner-tempthree", BannerTempThree);
    app.component("product-temp", ProductTemp);
    app.component("categories-temp", CategoriesTemp);
    app.component("categories-list-temp", CategoriesListTemp);
    app.component("product-recently", ProductRecentlyTemp);
    app.component("vue3-star-ratings", vue3StarRatings);
    app.component("the-boot-cum-temp", TheBootCumTemp);
    app.component("v-otp-input", VOtpInput);
    app.component("paginate", Paginate);
    app.component("custom-checkbox", customCheckbox);
    app.component("vertical-gallery", VerticalGallery);
    app.component(VueNumberInput.name, VueNumberInput);
    app.component("my-account-nav", MyAccountNav);
    app.component("my-account-side", MyAccountSide);
    app.component("product-side-template",ProductSideTemplate);
    app.component("product-checkout", ProductCheckout);
    app.component('vue-image-zoomer', VueImageZoomer);
    app.component('product-order', ProductOrder);
    app.component('saved-for-later', SavedForLater);
    // app.component('vue-base64-file-upload', VueBase64FileUpload);
  },
};
