import { createRouter, createWebHistory } from "vue-router";
import HomeView from "./../views/HomeView.vue";
import store from "./../store";

// const isToken = localStorage.getItem("token");

const authGuard = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next();
  } else {
    next("/user");
  }
};

const authAfterLog = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next("/account");
  } else {
    next();
  }
};

const orderTracking = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next("/ordertracking");
  } else {
    next("/account");
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/user",
    name: "user",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/LoginView.vue"),
    beforeEnter: authAfterLog,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/ForgotYourPasswordView.vue"
      ),
    beforeEnter: authAfterLog,
  },
  {
    path: "/newpassword",
    name: "newpassword",
    component: () =>
      import(
        /* webpackChunkName: "newpassword" */ "../views/NewPasswordView.vue"
      ),
    beforeEnter: authAfterLog,
  },
  {
    path: "/otp",
    name: "otp",
    component: () =>
      import(/* webpackChunkName: "otp" */ "../views/OtpView.vue"),
    beforeEnter: authAfterLog,
  },
  {
    path: "/account",
    name: "account",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/wishlist",
    name: "wishlist",
    component: () =>
      import(/* webpackChunkName: "wishlist" */ "../views/WishlistView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/shoppingcart",
    name: "shoppingcart",
    component: () =>
      import(
        /* webpackChunkName: "shoppingcart" */ "../views/ShoppingCartView.vue"
      ),
    beforeEnter: authGuard,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/order",
    name: "order",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/OrderView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/ordersuccessful/:id",
    name: "ordersuccessful",
    component: () =>
      import(
        /* webpackChunkName: "ordersuccessful" */ "../views/OrderSuccessfulView.vue"
      ),
    beforeEnter: authGuard,
  },
  {
    path: "/ordertracking",
    name: "ordertracking",
    component: () =>
      import(
        /* webpackChunkName: "ordertracking" */ "../views/OrderTrackingView.vue"
      ),
    beforeEnter: authGuard,
  },
  {
    path: "/categories",
    name: "allcategories",
    component: () =>
      import(
        /* webpackChunkName: "allcategories" */ "../views/AllCategoriesView.vue"
      ),
  },
  {
    path: "/categories/:slug",
    name: "productlist",
    component: () =>
      import(
        /* webpackChunkName: "productlist" */ "../views/ProductListView.vue"
      ),
  },
  {
    path: "/brands",
    name: "allbrands",
    component: () =>
      import(/* webpackChunkName: "allbrands" */ "../views/AllBrandsView.vue"),
  },
  {
    path: "/brands/:slug",
    name: "brandslist",
    component: () =>
      import(
        /* webpackChunkName: "brandslist" */ "../views/BrandsProductListView.vue"
      ),
  },
  {
    path: "/offers",
    name: "offers",
    component: () =>
      import(/* webpackChunkName: "offers" */ "../views/OffersView.vue"),
  },
  {
    path: "/offers/:slug",
    name: "offerslist",
    component: () =>
      import(
        /* webpackChunkName: "offerslist" */ "../views/OfferDetailsView.vue"
      ),
  },
  {
    path: "/product",
    name: "product",
    component: () =>
      import(
        /* webpackChunkName: "product" */ "../views/AllProductListView.vue"
      ),
  },
  {
    path: "/product/:slug",
    name: "productslug",
    component: () =>
      import(
        /* webpackChunkName: "productslug" */ "../views/ProductDetailsView.vue"
      ),
  },
  {
    path: "/search/:slug",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/SearchProductView.vue"),
  },
  {
    path: "/:slug",
    name: "pages",
    component: () =>
      import(/* webpackChunkName: "pages" */ "../views/PageView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
    // location.reload(0);
  },
});

export default router;
