const toTitleCase = (str) =>
  str
    .replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
    )
    .replace(/\s+$/g, " ")
    .replace(/\s+/g, "-");

const dash = (str) => str.replace(/\s+$/g, " ").replace(/\s+/g, "-");

const financial = (x) => {
  return Number.parseFloat(x).toFixed(2);
};

const generateId = (length = 12) => {
  return parseInt(
    Math.ceil(Math.random() * Date.now())
      .toPrecision(length)
      .toString()
      .replace(".", "")
  );
};

export { toTitleCase, dash, financial, generateId };
