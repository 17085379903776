<script setup>
import { defineProps } from 'vue'
const props = defineProps(['title']);
const primaryColor = props.title.primary;
</script>
<template>
  <div>
    <div class="primary-border-color">
        <h1 :class="`fs-${props.title.fontSize}`" class="fw-bold pb-3 m-0">{{ props.title.data.title }}</h1>
    </div>
    <slot name="data"></slot>
    <div class="border border-1 w-100"></div>
  </div>
</template>
<style lang="scss" scoped>
.primary-border-color {
  // border-bottom: 2px solid v-bind(primaryColor) !important;
  position: relative;
  top: 1px;
  display: inline-block;
}
</style>