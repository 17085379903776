<script setup>
import { defineProps } from "vue";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
const modules = [Navigation, Pagination, Scrollbar, A11y];
const props = defineProps(["category"]);
</script>
<template>
  <swiper
    :modules="modules"
    :slides-per-view="9"
    :space-between="0"
    navigation
    :breakpoints="{
      420: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
      1024: {
        slidesPerView: 8,
        spaceBetween: 0,
      },
    }"
    class="categorySwiper"
  >
    <swiper-slide
      v-for="(categoryData, categoryKey) in props.category.data"
      :key="categoryKey"
      :virtualIndex="categoryKey"
      class="p-3"
    >
      <router-link class="d-block" :to="`/categories/${categoryData.slug}`">
        <!-- <img
          v-if="categoryData.thumbnail_img"
          class="categoryImg w-100"
          :src="categoryData.thumbnail_img"
        />
        <img
          v-else
          class="categoryImg w-100"
          src="./../assets/images/Categories.png"
        /> -->
        <div
          v-if="categoryData.thumbnail_img"
          class="homeCategory"
          :style="{
            'background-image': 'url(' + categoryData.thumbnail_img + ')',
          }"
        ></div>
        <div
          v-else
          style="background-image: url(`./../assets/images/Categories.png`)"
          class="homeCategory"
        ></div>
        <h4 class="titleHomeCategory mt-3">{{ categoryData.name }}</h4>
      </router-link>
    </swiper-slide>
  </swiper>
</template>
<style lang="scss">
.categorySwiper {
  .swiper-wrapper {
    justify-content: center !important;
    display: flex !important;
    // .swiper-slide {
    // /* Center slide text vertically */
    //   display: -webkit-box;
    //   display: -ms-flexbox;
    //   display: -webkit-flex;
    //   display: flex;
    //   -webkit-box-pack: center;
    //   -ms-flex-pack: center;
    //   -webkit-justify-content: center;
    //   justify-content: center;
    //   -webkit-box-align: center;
    //   -ms-flex-align: center;
    //   -webkit-align-items: center;
    //   align-items: center;
    // }
  }
}
</style>
<style lang="scss" scoped>
a {
  display: block;
  text-decoration: none;

  .categoryImg {
    display: block;
    margin: 0 auto;
    border-radius: 100px;
  }

  .titleHomeCategory {
    display: block;
    text-align: center;
    color: #3c3c3c;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .homeCategory {
    height: 120px;
    width: 100%;
    border-radius: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
