<script setup>
import { ref, defineProps, computed } from "vue";
import { useStore } from "vuex";
import { financial } from "./../plugin/GlobalFuctions";
const props = defineProps(["item"]);
const store = useStore();
const qut = ref(props.item.qty);
const token = store.getters.isLoggedIn;
const removeFromCart = () => {
  let shopAccessToken = localStorage.getItem("shopAccessToken");
  let json = JSON.stringify({"cart_id": props.item.cart_id, "temp_user_id": shopAccessToken});
  store.dispatch("getDeleteFromCart", {json, token}).then(() => {
    store.dispatch("updateCartFromLocalStorage", token);
  })
}
const onUpdate = (newValue, oldValue) => {
  let shopAccessToken = localStorage.getItem("shopAccessToken");
  if(newValue > props.item.qty) {
    let json = JSON.stringify({"type": "plus", "cart_id": props.item.cart_id, "temp_user_id": shopAccessToken});
    store.dispatch("getAddQuantity", {json, token}).then(() => {
      store.dispatch("updateCartFromLocalStorage", token);
    })
  } else if (newValue < props.item.qty) {
    let json = JSON.stringify({"type": "minus", "cart_id": props.item.cart_id, "temp_user_id": shopAccessToken});
    store.dispatch("getAddQuantity", {json, token}).then(() => {
      store.dispatch("updateCartFromLocalStorage", token);
    })
  }
};
</script>
<template>
  <div class="card border-0 w-100 mb-3">
    <div class="row g-0">
      <div class="col-md-3">
        <div class="thumbnail-img">
            <img
                v-if="props.item.thumbnail"
                :src="props.item.thumbnail"
                :alt="props.item.name"
            />
          <img
            src="./../assets/images/product.png"
            alt="Product Image"
            class="w-100"
            v-else
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="card-body ms-2 p-0">
          <h5 class="card-title fs-6">
            {{props.item.name}}
          </h5>
          <vue-number-input
            class="w-75"
            size="small"
            @update:model-value="onUpdate"
            v-model="qut"
            :min="1"
            :max="props.item.max_qty"
            rounded
            inline
            center
            controls
          ></vue-number-input>
        </div>
      </div>
      <div class="col-md-3 text-end p-0">
        <button class="btn p-0" @click="removeFromCart"> <i class="bi bi-trash3"></i> </button>
        <h5 class="card-title">${{financial(props.item.dicounted_price)}}</h5>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.thumbnail-img {
  height: 90px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
