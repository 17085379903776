<script setup>
import { ref, defineProps, computed, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { financial } from "./../plugin/GlobalFuctions";
import { AccordionList, AccordionItem } from "vue3-rich-accordion";
import "vue3-rich-accordion/accordion-library-styles.css";
const props = defineProps(["router", "route"]);
const store = useStore();
const primary = ref(store.getters.primaryColor);
const whiteColor = ref(store.getters.whiteColor);
const searchData = ref("");
const menu = ref({});
const category = ref([]);

const item = computed(() =>
  store.getters.cartItems !== null ? store.getters.cartItems : []
);
const cartTotal = computed(() =>
  store.getters.cartTotal > 0 ? store.getters.cartTotal : 0
);
const length = computed(() =>
  store.getters.cartLength > 0 ? store.getters.cartLength : 0
);
const wishlistLength = computed(() => {
  return store.getters.wishListLength;
});
// > 0 ? store.getters.wishListLength : 0
const token = computed(() =>
  store.getters.isLoggedIn !== null ? store.getters.isLoggedIn : ""
);
const footerData = ref("");
onMounted(() => {
  if (token) {
    store.dispatch("getWishlistsShow", token);
  }
  store.dispatch("getCategories").then(() => {
    category.value = store.getters.categoriesData.data;
    store.dispatch("getHeader").then(() => {
      menu.value = store.getters.headerData;
    });
  });
  store.dispatch("getFooter").then(() => {
    footerData.value = store.getters.footerData;
  });
});
const search = () => {
  if (searchData.value) {
    props.router.push({ path: `/search/${searchData.value}` }).then(() => {
      searchData.value = "";
      // location.reload();
    });
  }
};
const user = () => {
  props.router.push("/order");
};
const wishlistClick = () => {
  if (token) {
    props.router.push({ path: "/wishlist" });
  } else {
    props.router.push("/user");
  }
};
const cartClick = () => {
  if (token) {
    props.router.push({ name: "shoppingcart" });
  } else {
    props.router.push("/account");
  }
};
// const orderTrackClick = () => {
//   console.log(token.value)
//   if (token.value) {
//     props.router.push({ path: '/ordertracking' });
//   } else {
//     props.router.push({ path: "/account" });
//   }
// }
const categoryAccordion = (data) => {
  props.router.push(`/categories/${data}`);
  // .then(() => {
  //   // location.reload(0)
  // });
};
</script>
<template>
  <nav class="navbar navbar-light bg-light nav-bg-color py-1">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="p-0" style="width: 20%">
          <router-link
            class="navbar-brand"
            to="/"
            style="display: inline-block"
          >
            <!-- <img src="./../assets/images/Logo.png" alt="logo" /> -->
            <img :src="footerData.footer_logo" alt="Logo" class="w-50" />
          </router-link>
        </div>
        <!-- d-flex justify-content-between -->
        <div class="w-50 p-0 d-none d-md-block">
          <div class="input-group">
            <!-- <select class="form-select" aria-label="Default select">
              <option selected>All Categories</option>
              <option
                v-for="(categoryData, categoryKey) in category"
                :key="categoryKey"
                :value="`/categories/${categoryData.slug}`"
              >
                {{categoryData.name}}
              </option>
            </select> -->
            <input
              type="text"
              class="form-control"
              placeholder="Search for products"
              aria-label="Search for products"
              v-model="searchData"
              @keyup.enter="search"
            />
            <button class="btn searchBtn" type="button" @click="search">
              <i class="bi bi-search"></i>
            </button>
          </div>
        </div>
        <div class="som-topHeader-Icon">
          <ul>
            <li>
              <a href="javascript:void(0)" class="btn px-1 py-0" @click="user">
                <!-- <i class="bi bi-person-fill fs-4" v-if="token"></i>
                <i class="bi bi-person fs-4" v-else></i> -->
                <img
                  src="./../assets/images/person-fill.png"
                  width="36"
                  v-if="token"
                />
                <img src="./../assets/images/person.png" width="36" v-else />
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                class="btn px-1 py-0"
                @click="wishlistClick"
              >
                <!-- <i class="bi bi-heart fs-4"></i> -->
                <img src="./../assets/images/heart.png" width="36" />
                <span
                  class="position-absolute translate-middle rounded-pill cart-count badge"
                  style="top: 5px; background-color: #d22636"
                  v-if="wishlistLength > 0"
                  >{{ wishlistLength }}</span
                >
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                data-bs-toggle="offcanvas"
                data-bs-target="#nabsidemenuRight"
                aria-controls="nabsidemenuRight"
                class="btn px-1 py-0"
              >
                <!-- <i class="bi bi-cart fs-4"></i> -->
                <img src="./../assets/images/cart.png" width="36" />
                <!-- <span>Cart</span> -->
                <span
                  class="position-absolute translate-middle rounded-pill cart-count badge"
                  style="top: 5px; background-color: #d22636"
                  v-if="length"
                  >{{ length }}</span
                >
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-100 p-0 my-1 d-sm-none">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Search for products"
            aria-label="Search for products"
            v-model="searchData"
            @keyup.enter="search"
          />
          <button class="btn searchBtn" type="button" @click="search">
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
    </div>
  </nav>
  <nav class="navbar navbar-expand-lg navbar-light primary p-0">
    <div class="container">
      <a
        class="navbar-brand primary2-text"
        data-bs-toggle="offcanvas"
        href="#nabsidemenu"
      >
        <i class="bi bi-list"></i>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon text-light"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li
            class="nav-item"
            v-for="(menuData, menuKey) in menu.header_menu"
            :key="menuKey"
          >
            <router-link
              class="nav-link primary2-text font-12px px-3"
              :to="menuData"
              >{{ menuKey }}</router-link
            >
          </li>
        </ul>
        <!-- <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <div type="button" class="nav-link primary2-text font-12px" @click="orderTrackClick">
              Order Tracking
            </div>
          </li>
        </ul> -->
      </div>
    </div>
  </nav>
  <div
    class="offcanvas offcanvas-start shadow-lg border-0 sideCategories"
    tabindex="-1"
    id="nabsidemenu"
    aria-labelledby="offcanvasCategory"
    data-bs-scroll="true"
  >
    <div class="offcanvas-header primary">
      <h5 class="offcanvas-title white-text" id="offcanvasCategory">
        CATEGORIES
      </h5>
      <button
        type="button"
        class="btn-close text-reset white-text"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body p-0">
      <!-- <div class="accordion accordion-flush" id="accordionFlushExample">

        <div class="accordion-item" v-for="(categoryData, categoryKey) in category" :key="categoryKey">

          <h2 class="accordion-header" :id="`flush-headingOne-${categoryData.slug}`" v-if="categoryData.children.data.length > 0">
            <div class="accordion-button collapsed py-3" data-bs-toggle="collapse"
              :data-bs-target="`#flush-${categoryData.slug}`" aria-expanded="false" aria-controls="flush-collapseOne">
              <b type="button" @click="categoryAccordion(categoryData.slug)" data-bs-dismiss="offcanvas"
                aria-label="Close">{{ categoryData.name }}</b>
            </div>
          </h2>
          <h2 class="accordion-header category-button" v-else>
            <div class="accordion-button collapsed category-button py-3">
              <b style="cursor: pointer;" @click="categoryAccordion(categoryData.slug)" data-bs-dismiss="offcanvas"
                aria-label="Close">{{ categoryData.name }}</b>
            </div>
          </h2>
          <div :id="`flush-${categoryData.slug}`" v-if="categoryData.children.data.length > 0"
            class="accordion-collapse collapse p-3" :aria-labelledby="`flush-headingOne-${categoryData.slug}`"
            data-bs-parent="#accordionFlushExample">
            <div data-bs-dismiss="offcanvas" aria-label="Close">
              <router-link 
                class="bottom-footer text-decoration-none"
                :to="`/categories/${categoryData.slug}`"
              >
                <b>{{categoryData.name}}</b>
              </router-link>
            </div>
            <ul class="list-group">
              <li class="list-group-item border-0 px-0 py-1"
                v-for="(subCategoryList, subCategoryKey) in categoryData.children.data" :key="subCategoryKey"
                data-bs-dismiss="offcanvas" aria-label="Close">
                <div class="bottom-footer text-decoration-none" @click="categoryAccordion(subCategoryList.slug)"
                  style="cursor: pointer;">
                  <span class="fs-6">{{ subCategoryList.name }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
      <AccordionList open-multiple-items>
        <AccordionItem
          v-for="(categoryData, categoryKey) in category"
          :key="categoryKey"
        >
          <template #summary
            ><div class="text-dark">{{ categoryData.name }}</div></template
          >
          <template #icon
            ><span style="font-size: 10px; color: black">V</span></template
          >
          <a
            href="javascript:void(0)"
            class="text-reset text-decoration-none"
            @click="categoryAccordion(categoryData.slug)"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            ><h4>{{ categoryData.name }}</h4></a
          >
          <ul class="list-group">
            <li
              class="list-group-item border-0 px-0 py-1"
              v-for="(subCategoryList, subCategoryKey) in categoryData.children
                .data"
              :key="subCategoryKey"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <div
                class="bottom-footer text-decoration-none"
                @click="categoryAccordion(subCategoryList.slug)"
                style="cursor: pointer"
              >
                <span class="fs-6">{{ subCategoryList.name }}</span>
              </div>
            </li>
          </ul>
        </AccordionItem>
      </AccordionList>
    </div>
  </div>
  <div
    class="offcanvas offcanvas-end border-0 shadow-lg"
    tabindex="-1"
    id="nabsidemenuRight"
    aria-labelledby="nabsidemenuRightLabel"
    data-bs-scroll="true"
  >
    <div class="offcanvas-header border-bottom">
      <h5 id="nabsidemenuRightLabel" class="fs-4 mb-0">
        <i class="bi bi-cart primary-text me-2"></i> {{ length }} item(s)
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <product-side-template
        v-for="(itemData, itemKey) in item"
        :key="itemKey"
        :item="itemData"
        v-if="length > 0"
      ></product-side-template>
      <div class="d-flex align-content-center flex-wrap h-100" v-else>
        <!-- <img src="./../assets/images/no-cart-item.png" class="align-middle w-75" style="margin: auto;" /> -->
        <h3 class="text-center">Your shopping bag is empty. Start shopping.</h3>
      </div>
    </div>
    <div class="offcanvas-footer border-top p-3">
      <!-- <div class="input-group mb-3">
        <span class="input-group-text bg-white-color border-0"
          >Have a code?</span
        >
        <input type="text" class="form-control" placeholder="Coupon code" />
        <span class="input-group-text bg-white-color border-0 p-0">
          <button type="submit" class="btn rounded-0 primary white-text ms-1">Apply</button>
        </span>
      </div> -->
      <button
        class="btn primary white-text w-100"
        v-if="length > 0"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        type="submit"
        @click="cartClick"
      >
        Checkout<span class="ms-2">${{ financial(cartTotal) }}</span>
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
ul.navbar-nav {
  li {
    .router-link-exact-active {
      background-color: #5daeff !important;
    }

    &:hover {
      background-color: #5daeff !important;
    }
  }
}

.input-group {
  position: inherit !important;
  flex-wrap: inherit !important;
}

.som-topHeader-Icon {
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    li {
      display: inline-block;

      .btn:first-child:active {
        border: 0px !important;
      }

      // a {
      //   display: block;
      //   text-decoration: none;
      //   color: #000000;
      //   padding: 10px;
      //   i {
      //     display: block;
      //     &::before {
      //       text-align: center;
      //       display: block;
      //       font-size: 1.2rem;
      //     }
      //   }
      //   span {
      //     font-size: 12px;
      //   }
      //   .cart-count {
      //     top: 25px !important;
      //     margin-left: 1.5rem;
      //     display: inline-table;
      //   }
      // }
    }
  }
}

.font-12px {
  font-size: 14px !important;
}

.bottom-footer {
  color: var(--gray-color) !important;

  &:hover {
    color: var(--black-color) !important;
  }
}

.searchBtn {
  background-color: v-bind(primary);
  color: v-bind(whiteColor);
}

.sideCategories {
  .category-button {
    &::after {
      background-image: none !important;
    }
  }
}
.accordion-item {
  border-radius: 0px !important;
  border: 0px;
  margin: 0px;
  .accordion-item__content {
    border-top: 0px !important ;
  }
}
</style>
