import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import URL_API from "./BaseUrl";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const api = axios.create({
  adapter: cache.adapter,
});
const HomeService = {
  homeDataApi(slug) {
    return api({
      url: `${URL_API}setting/home/${slug}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  headerDataApi() {
    return api({
      url: `${URL_API}setting/header`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  footerDataApi() {
    return api({
      url: `${URL_API}setting/footer`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  categoriesDataApi() {
    return api({
      url: `${URL_API}all-categories`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productListDataApi() {
    return api({
      url: `${URL_API}product/search`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productCategoryApi(slug) {
    return api({
      url: `${URL_API}product/search?category_slug=${slug}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productListPageApi(page) {
    return api({
      url: `${URL_API}product/search?page=${page}`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productBrandApi(slug) {
    return api({
      url: `${URL_API}product/search?category_slug=${slug.category}&brand_ids=${slug.brands}&sort_by=${slug.sortByValue}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productSearchDataApi(slug) {
    return api({
      url: `${URL_API}product/search?category_slug=${slug.category}&min_price=${slug.minPrice}&max_price=${slug.maxPrice}&brand_ids=${slug.brands}&sort_by=${slug.sortByValue}&attribute_values=${slug.attributesValue}`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productSearch(json) {
    return api({
      url: `${URL_API}product/search?keyword=${json}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  brandListDataApi() {
    return api({
      url: `${URL_API}all-brands`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  offerListDataApi() {
    return api({
      url: `${URL_API}all-offers`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productDetailsApi(slug) {
    return api({
      url: `${URL_API}product/details/${slug}`,
      method: "get",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productReviews(id) {
    return api({
      url: `${URL_API}product/reviews/${id}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  productRelated(id) {
    return api({
      url: `${URL_API}product/related/${id}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  offers() {
    return api({
      url: `${URL_API}all-offers`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  offersData(slug) {
    return api({
      url: `${URL_API}offer/${slug}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  pageData(slug) {
    return api({
      url: `${URL_API}page/${slug}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
};

export default HomeService;

// &keyword=${slug}
