<script setup>
import { defineProps } from "vue";
const props = defineProps(["account"]);
const primary = props.account.primary;
</script>
<template>
  <nav class="nav nav-pills flex-column flex-sm-row">
    <router-link
      v-for="(accountData, accountKey) in props.account.ac"
      :key="accountKey"
      class="flex-sm-fill text-sm-center nav-link rounded-0"
      :class="accountData.class"
      :to="accountData.url"
    >
      {{ accountData.title }}
    </router-link>
  </nav>
</template>
<style lang="scss" scoped>
.nav-link {
  color: #4e4e4e !important;
  background: #F7F7F7;
}
.activeColor {
  border-bottom: 2px solid v-bind(primary) !important;
}
</style>
