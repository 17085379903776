<script setup>
  import { defineProps } from "vue";
  import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
  import { Swiper, SwiperSlide } from "swiper/vue";
  const modules = [Navigation, Pagination, Scrollbar, A11y];
  const props = defineProps(['banner']);
</script>
<template>
  <div class="row gx-0">
    <div class="col-md-9">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        navigation
        :pagination="{ clickable: true }"
        :loop="true"
      >
        <swiper-slide
          v-for="(bannerData, bannerKey) in props.banner.one"
          :key="bannerKey"
          :virtualIndex="bannerKey"
        >
          <router-link
            :to="bannerData.link == null ? `/` : bannerData.link"
            class="bannerImg9"
            :style="`background-image: url(${bannerData.img})`"
          ></router-link>
        </swiper-slide>
      </swiper>
    </div>
    <div class="col-md-3">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        :pagination="{ clickable: true }"
        :loop="true"
      >
        <swiper-slide
          v-for="(bannerData, bannerKey) in props.banner.two"
          :key="bannerKey"
        >
          <router-link
            :to="bannerData.link == null ? `/` : bannerData.link"
            class="bannerImg3"
            :style="`background-image: url(${bannerData.img})`"
          ></router-link>
        </swiper-slide>
      </swiper>
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        :pagination="{ clickable: true }"
        :loop="true"
      >
        <swiper-slide
          v-for="(bannerData, bannerKey) in props.banner.three"
          :key="bannerKey"
        >
          <router-link
            :to="bannerData.link == null ? `/` : bannerData.link"
            class="bannerImg3"
            :style="`background-image: url(${bannerData.img})`"
          ></router-link>
        </swiper-slide>
      </swiper>
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        :pagination="{ clickable: true }"
        :loop="true"
      >
        <swiper-slide
          v-for="(bannerData, bannerKey) in props.banner.four"
          :key="bannerKey"
        >
          <router-link
            :to="bannerData.link == null ? `/` : bannerData.link"
            class="bannerImg3"
            :style="`background-image: url(${bannerData.img})`"
          ></router-link>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bannerImg9 {
  display: block;
  height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
}
.bannerImg3 {
  display: block;
  height: 183px;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
<style lang="scss">
.swiper-button-prev, .swiper-button-next {
  background-color: #ffffff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  -webkit-box-shadow: 0px 0px 19px 4px rgba(201,201,201,0.8);
  -moz-box-shadow: 0px 0px 19px 4px rgba(201,201,201,0.8);
  box-shadow: 0px 0px 19px 4px rgba(201,201,201,0.8);
  &::after {
    color: black;
    font-size: 20px;
  }
}
</style>