<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
const category = ref([]);
const footerData = ref({});
const contact_info = ref({});
const footer_link_one = ref({});
const footer_link_two = ref({});
const mobile_app_links = ref({});
store.dispatch("getFooter").then(() => {
  footerData.value = store.getters.footerData;
  contact_info.value = store.getters.footerData.contact_info;
  footer_link_one.value = store.getters.footerData.footer_link_one;
  footer_link_two.value = store.getters.footerData.footer_link_two;
  mobile_app_links.value = store.getters.footerData.mobile_app_links;
  store.dispatch("getCategories").then(() => {
    category.value = store.getters.categoriesData.data;
  });
})
</script>
<template>
  <div class="primary2">
    <footer class="container py-5">
      <div class="row">
        <div class="col-md-3">
          <!-- <h5 class="fw-bold">Contact Us</h5> -->
          <img :src="footerData.footer_logo" class="w-75" />
          <div class="row my-4">
            <div class="col-3 d-flex flex-wrap align-items-center">
              <i class="bi bi-telephone-fill primary-text fs-1"></i>
            </div>
            <div class="col-9">
              <h5>Need help? 24/7</h5>
              <h4 class="m-0">{{ contact_info.contact_phone }}</h4>
            </div>
          </div>
          <p>
            {{contact_info.contact_address}}
          </p>
        </div>
        <div class="col-md-2">
          <h5 class="fw-bold">{{footer_link_one.title}}</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2" v-for="(menuValue, menuKey) in footer_link_one.menu" :key="menuKey">
              <router-link :to="`${menuValue}`" class="nav-link p-0 text-muted">{{menuKey}}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-2">
          <h5 class="fw-bold">{{footer_link_two.title}}</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2" v-for="(menuValue, menuKey) in footer_link_two.menu" :key="menuKey">
              <router-link :to="`${menuValue}`" class="nav-link p-0 text-muted">{{menuKey}}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-2">
          <h5 class="fw-bold">Others</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2" v-for="(menuValue, menuKey) in footerData.footer_menu" :key="menuKey">
              <router-link :to="`${menuValue}`" class="nav-link p-0 text-muted">{{menuKey}}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <form>
            <h5 class="fw-bold">Get The Latest Deals & More</h5>
            <p>Receive 10% OFF your next order, exclusive offers & more!</p>
            <div class="d-flex w-100 gap-0">
              <label for="newsletter1" class="visually-hidden"
                >Your email address</label
              >
              <input
                id="newsletter1"
                type="text"
                class="form-control rounded-0 fs-6"
                placeholder="Your email address"
                spellcheck="false"
                data-ms-editor="true"
              />
              <button class="btn rounded-0 primary white-text" type="button">SIGNUP</button>
            </div>
            <h5 class="mt-3">Best Buy App:</h5>
            <div class="row">
              <div class="col-12">
                <a v-bind:href="mobile_app_links.app_store" class="d-inline-block">
                  <img src="./../assets/images/AppStore.png" alt="AppStore"/>
                </a>
                <a :href="mobile_app_links.play_store" class="d-inline-block">
                  <img src="./../assets/images/GooglePlay.png" alt="GooglePlay"/>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <div class="py-4 my-4 border-top mb-0">
        <ul
          class="list-unstyled d-flex m-0"
          v-for="(categoryList, categoryKey) in category"
          :key="categoryKey"
        >
          <li>
            <h6>
              <b>{{ categoryList.name }}:</b>
            </h6>
          </li>
          <li class="ms-3">
            <p class="mb-0">
              <span
                v-for="(
                  subCategoryList, subCategoryKey
                ) in categoryList.children.data"
                :key="subCategoryKey"
              >
                <router-link class="text-decoration-none bottom-footer" :to="`/categories/${subCategoryList.slug}`">{{ subCategoryList.name }}</router-link><span> | </span>
              </span>
            </p>
          </li>
        </ul>
      </div> -->
      <div class="d-flex justify-content-between py-4 m-0 border-top">
        <p><div v-html='footerData.copyright_text == null ? "Null" : footerData.copyright_text'></div></p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3">
            <h5>Payment :</h5>
          </li>
          <li class="ms-3">
            <a class="link-dark" href="#">
              <img src="./../assets/images/payment.png" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>
<style lang="scss" scoped>
.primary2 {
  background: #f7f7f7;
}
.bottom-footer {
  color: var(--gray-color) !important;
  &:hover {
    color: var(--black-color) !important;
  }
}
</style>
