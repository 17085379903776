import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createMetaManager } from "vue-meta";
import "@popperjs/core";
import "bootstrap";
import BootstrapVue3 from 'bootstrap-vue-3';
import "./assets/scss/custom.scss";
import "./assets/js/bootstrap.bundle.min.js";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import GlobalComponents from "./plugin/GlobalComponents";
import VueProgressBar from "@aacassandra/vue3-progressbar";

import Axios from "axios";
// set auth header
Axios.defaults.headers.common["Authorization"] = `Bearer ${store.getters.isLoggedIn}`;

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
const globalOptions = {
  mode: 'auto',
};

const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "left",
  inverse: false,
};

const app = createApp(App);
app.use(store);
app.use(router);
app.use(GlobalComponents);
app.use(BootstrapVue3);
app.use(createMetaManager());
app.use(VueProgressBar, options);
app.use(VueTelInput, globalOptions);
app.mount("#app");


