<script setup>
import { ref, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { useMeta } from "vue-meta";
useMeta({
  title: "Online Shopping Site for Mobile, Electronics, Fashion, Grocery | moncongoexpress.com",
  meta: [
    { name: 'description', content: '' },
    { name: 'keywords', content: '' }
  ],
  og: {
    title: "Online Shopping Site for Mobile, Electronics, Fashion, Grocery | moncongoexpress.com",
    image: "http://webadmin.essentialaquatech.com/api/uploadImages/1654256634762.png",
  },
});
const store = useStore();

const primary = ref(store.getters.primaryColor);
const whiteColor = ref(store.getters.whiteColor);
const fontSize = ref(4);
const categoryTitle = ref({ "data": { "title": "Shop By Categories" }, "primary": primary, "fontSize": fontSize, "whiteColor": whiteColor });

const bannerDataList = ref({});
const productBlackFriday = ref({});
const category = ref({});
const bannerSectionOne = ref({});
const productSectionOne = ref({});
const bannerSectionTwo = ref({});
const productSectionTwo = ref({});
const bannerSectionThree = ref({});
const productSectionThree = ref({});
const bannerSectionFour = ref({});
const productSectionFour = ref({});
const productSectionSix = ref({});

const pdfResults = ref([]);
const psoResults = ref([]);
const pstwoResults = ref([]);
const psthreeResults = ref([]);
const psfourResults = ref([]);
const pssixResults = ref([]);
const bannerSix = ref({});

onMounted(() => {
  store.dispatch("getHome", "sliders").then(() => {
    bannerDataList.value = store.getters.homeData;
    store.dispatch("getHome", "product_section_three").then(() => {
      productBlackFriday.value = store.getters.homeData;
      pdfResults.value = store.getters.homeData.products.data;
      store.dispatch("getHome", "popular_categories").then(() => {
        category.value = store.getters.homeData;
        store.dispatch("getHome", "banner_section_one").then(() => {
          bannerSectionOne.value = store.getters.homeData;
          store.dispatch("getHome", "product_section_one").then(() => {
            productSectionOne.value = store.getters.homeData;
            psoResults.value = store.getters.homeData.products.data;
            store.dispatch("getHome", "banner_section_two").then(() => {
              bannerSectionTwo.value = store.getters.homeData;
              store.dispatch("getHome", "product_section_two").then(() => {
                productSectionTwo.value = store.getters.homeData;
                pstwoResults.value = store.getters.homeData.products.data;
                store.dispatch("getHome", "banner_section_three").then(() => {
                  bannerSectionThree.value = store.getters.homeData;
                  store.dispatch("getHome", "product_section_four").then(() => {
                    productSectionThree.value = store.getters.homeData;
                    psthreeResults.value = store.getters.homeData.products.data;
                    store.dispatch("getHome", "banner_section_four").then(() => {
                      bannerSectionFour.value = store.getters.homeData;
                      store.dispatch("getHome", "product_section_five").then(() => {
                        productSectionFour.value = store.getters.homeData;
                        psfourResults.value = store.getters.homeData.products.data;
                        store.dispatch("getHome", "product_section_six").then(() => {
                          productSectionSix.value = store.getters.homeData;
                          pssixResults.value = store.getters.homeData.products.data;
                          bannerSix.value = store.getters.homeData.banner;
                        })
                      })
                    })
                  });
                });
              });
            });
          });
        });
      });
    });
  });
})
</script>
<template>
  <banner-temptwo :banner="bannerDataList"></banner-temptwo>
  <!-- Black Friday Sale -->
  <div class="container">
    <templates v-if="productBlackFriday.title !== null">
      <home-title
        :title='{ "data": productBlackFriday, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize }'
        class="mb-4 pt-5"></home-title>
      <div class="row row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-6 g-0 g-lg-0">
        <templates class="col-12" v-for="(pbfd, pbfk) in pdfResults.slice(0, 6)" :key="pbfk">
          <product-temp
            :product='{ "data": pbfd, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize, "fab": false }'></product-temp>
        </templates>
      </div>
    </templates>
    <!-- categories -->
    <templates v-if="categoryTitle.title !== null">
      <home-title :title="categoryTitle" class="mb-4 pt-5" />
      <categories-temp :category="category"></categories-temp>
    </templates>

    <templates v-if="productSectionOne.title !== null">
      <!-- <banner-tempone :banner="bannerSectionOne"></banner-tempone> -->
      <banner-tempthree :banner="{ 'one': bannerSectionOne, 'two': bannerDataList }"></banner-tempthree>
      <home-title
        :title='{ "data": productSectionOne, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize }'
        class="mb-4 pt-5"></home-title>
      <div class="row row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-6 g-0 g-lg-0">
        <templates class="col-12" v-for="(psod, psok) in psoResults.slice(0, 6)" :key="psok">
          <product-temp
            :product='{ "data": psod, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize, "fab": false }'></product-temp>
        </templates>
      </div>
    </templates>

    <templates v-if="productSectionTwo.title !== null">
      <banner-tempone :banner="bannerSectionTwo"></banner-tempone>
      <home-title
        :title='{ "data": productSectionTwo, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize }'
        class="mb-4 pt-5"></home-title>
      <div class="row row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-6 g-0 g-lg-0">
        <div class="col-12" v-for="(pstwoData, pstwoKey) in pstwoResults.slice(0, 6)" :key="pstwoKey">
          <product-temp
            :product='{ "data": pstwoData, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize, "fab": false }'></product-temp>
        </div>
      </div>
    </templates>

    <templates v-if="productSectionThree.title !== null">
      <banner-tempone :banner="bannerSectionThree"></banner-tempone>
      <home-title
        :title='{ "data": productSectionThree, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize }'
        class="mb-4 pt-5"></home-title>
      <div class="row row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-6 g-0 g-lg-0">
        <div class="col-12" v-for="(psthreeData, psthreeKey) in psthreeResults.slice(0, 6)" :key="psthreeKey">
          <product-temp
            :product='{ "data": psthreeData, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize, "fab": false }'></product-temp>
        </div>
      </div>
    </templates>

    <templates v-if="productSectionFour.title !== null">
      <banner-tempone :banner="bannerSectionFour"></banner-tempone>
      <home-title
        :title='{ "data": productSectionFour, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize }'
        class="mb-4 pt-5"></home-title>
      <div class="row row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-6 g-0 g-lg-0">
        <div class="col-12" v-for="(psfourData, psfourKey) in psfourResults.slice(0, 6)" :key="psfourKey">
          <product-temp
            :product='{ "data": psfourData, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize, "fab": false }'></product-temp>
        </div>
      </div>
    </templates>

    <templates v-if="productSectionSix.title !== null">
      <div class="d-block">
        <router-link :to="bannerSix.link == null ? `/` : bannerSix.link">
          <img class="w-100" :src="bannerSix.img" />
        </router-link>
      </div>
      <home-title
        :title='{ "data": productSectionSix, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize }'
        class="mb-4 pt-5"></home-title>
      <div class="row row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-6 g-0 g-lg-0 mb-3">
        <div class="col-12" v-for="(pssixData, pssixKey) in pssixResults.slice(0, 6)" :key="pssixKey">
          <product-temp
            :product='{ "data": pssixData, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize, "fab": false }'></product-temp>
        </div>
      </div>
    </templates>

    <!-- <templates v-if="productSectionSix.title !== null">
      <div class="d-block">
        <router-link :to="bannerSix.link == null ? `/` : bannerSix.link">
          <img class="w-100" :src="bannerSix.img" />
        </router-link>
      </div>
      <home-title :title='{"data": productSectionSix, "primary": primary, "whiteColor": whiteColor, "fontSize": fontSize}' class="mb-4 pt-5"></home-title>
      <div class="row row-cols-xs-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-6 g-0 g-lg-0 mb-3">
        <div
          class="col-12"
          v-for="(pssixData, pssixKey) in pssixResults.slice(0,6)"
          :key="pssixKey"
        >
          <product-recently :product='{"data": pssixData}'></product-recently>
        </div>
      </div>
    </templates> -->
  </div>
</template>
