<script setup>
  import { defineProps } from 'vue'
  import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
  import { Swiper, SwiperSlide } from "swiper/vue";
  const modules = [Navigation, Pagination, Scrollbar, A11y];
  const props = defineProps(['banner']);
</script>
<template>
  <div class="row gx-0">
    <div class="col-12">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        navigation
        :pagination="{ clickable: true }"
        :loop="true"
      >
        <swiper-slide
          v-for="(bannerData, bannerKey) in props.banner"
          :key="bannerKey"
          :virtualIndex="bannerKey"
        >
          <router-link
            :to="bannerData.link == null ? `/` : bannerData.link"
            class="bannerImg9"
            :style="`background-image: url(${bannerData.img})`"
          ></router-link>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .bannerImg9 {
    display: block;
    height: 350px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
</style>
