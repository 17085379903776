import WishlistCart from "./../server/WishlistCartServer";
import { generateId } from "./../plugin/GlobalFuctions";
import Swal from 'sweetalert2';
import store from "./../store";
function updateLocalStorage() {
  // localStorage.setItem("cart", JSON.stringify(cart));
  let gId = generateId();
  localStorage.setItem("shopAccessToken", gId);
}

const CartPageStore = {
  state: () => ({
    cart: null,
    address: null,
    countries: null,
    stateData: null,
    cityData: null,
    shippingCost: null,
    coupon: null,
    checkout:null,
    ordercode: null,
    orderListData: null
  }),
  getters: {
    productQuantity: (state) => (product) => {
      const item = state.cart.find((i) => i.id === product.id);
      if (item) return item.qty;
      else return null;
    },
    cartItems: (state) => {
      return state.cart == null ? null : state.cart.cart_items.data;
    },
    cartLength: (state) => {
      return state.cart == null ? 0 : state.cart.cart_items.data.length;
    },
    cartTotal: (state) => {
      return state.cart == null ? 0 : state.cart.cart_items.data.reduce((a, b) => a + (b.dicounted_price * b.qty), 0);
    },
    cartRegularPrice: (state) => {
      return state.cart == null ? 0 : state.cart.cart_items.data.reduce((a, b) => a + (b.regular_price * b.qty), 0);
    },
    cartTax: (state) => {
      return state.cart == null ? 0 : state.cart.cart_items.data.reduce((a, b) => a + (b.tax * b.qty), 0);
    },
    address: (state) => {
      return state.address != null ? state.address.data : {};
    },
    countries: (state) => {
      return state.countries;
    },
    stateData: (state) => {
      return state.stateData;
    },
    cityData: (state) => {
      return state.cityData;
    },
    shippingCost: (state) => {
      return state.shippingCost;
    },
    coupon: (state) => {
      return state.coupon
    },
    checkout: (state) => {
      return state.checkout;
    },
    ordercode: (state) => {
      return state.ordercode != null ? state.ordercode : {"success": false};
    },
    orderListData: (state) => {
      return state.orderListData !== null ? state.orderListData.data : "";
    }
  },
  mutations: {
    ADDTOCART(state, product) {
      let item = state.cart.find((i) => i.id === product.id);
      if (item) {
        item.qty++;
      } else {
        state.cart.push({ ...product, qty: 1 });
      }
      updateLocalStorage(state.cart);
    },
    removeFromCart(state, product) {
      let token = state.isLoggedIn;
      if(token) {

      }else {
        let item = state.cart.find((i) => i.id === product.id);
        if (item) {
          if (item.qty > 1) {
            item.qty--;
          } else {
            state.cart = state.cart.filter((i) => i.id !== product.id);
          }
        }
        updateLocalStorage(state.cart);
      }
    },
    deleteFromCart(state, product) {
      let item = state.cart.find((i) => i.id === product.id);
      let token = state.isLoggedIn;
      if(token) {

      } else {
        if (item) {
          state.cart = state.cart.filter((i) => i.id !== product.id);
        }
        updateLocalStorage(state.cart);
      }
    },
    UPDATECART: (state, response) => {
      state.cart = response;
    },
    UPDATECARTNULL: (state) => {
      state.cart = null;
    },
    ADDRESS: (state, response) => {
      state.address = response;
    },
    COUNTRIES: (state, response) => {
      state.countries = response;
    },
    STATEDATA: (state, response) => {
      state.stateData = response;
    },
    CITYDATA: (state, response) => {
      state.cityData = response;
    },
    SHIPPINGCOST: (state, response) => {
      state.shippingCost = response;
    },
    COUPON: (state, response) => {
      state.coupon = response
    },
    CHECKOUT: (state, response) => {
      state.checkout = response
    },
    ORDERCODE: (state, response) => {
      state.ordercode = response;
    },
    ORDERLISTDATA: (state, response) => {
      state.orderListData = response;
    }
  },
  actions: {
    updateCartFromLocalStorage: async ({ commit }, token) => {
      let shopAccessToken = localStorage.getItem("shopAccessToken");
      let json = JSON.stringify({"temp_user_id":shopAccessToken})
      const response = await WishlistCart.carts(json, token);
      commit("UPDATECART", response);
    },
    getAddToCart: async ({ commit }, {token, json, qty}) => {
      let data = JSON.parse(json);
      let qtyTotal = qty;
      let variations = data.variations[0].id;
      if(token) {
        let json = JSON.stringify({"variation_id":variations,"qty":qtyTotal,"temp_user_id":null});
        const response = await WishlistCart.addCarts(json, token);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
      } else {
        if(localStorage.getItem("shopAccessToken") === null) {
          updateLocalStorage();
        }
        let shopAccessToken = localStorage.getItem("shopAccessToken");
        if(shopAccessToken) {
          let json = JSON.stringify({"variation_id":variations,"qty":qtyTotal,"temp_user_id":shopAccessToken});
          const response = await WishlistCart.addCarts(json, token);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            toast: true,
            title: `${response.message}`,
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true
          })
        }
      }
    },
    getAddQuantity: async ({ commit }, {json, token}) => {
      const response = await WishlistCart.changeQuantity(json, token);
      if(response.success === true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
      }
    },
    getDeleteFromCart: async ({ commit }, {json, token}) => {
      const response = await WishlistCart.deleteFromCart(json, token);
      if(response.success === true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
      }
    },
    getShippingCost: async ({ commit }, id) => {
      const response = await WishlistCart.shippingCost(id);
      commit("SHIPPINGCOST", response);
    },
    getAddress: async ({ commit }) => {
      const response = await WishlistCart.addresses();
      commit("ADDRESS", response);
    },
    getAddAddress: async ({ commit }, json) => {
      const response = await WishlistCart.addAddresses(json);
      if(response.success === true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
        let res = {
          "message": response.message,
          "success": response.success,
          "data": store.getters.address.concat(response.data)
        }
        commit("ADDRESS", res);
      }
    },
    getUpdateAddress: async ({ commit }, json) => {
      const response = await WishlistCart.updateAddresses(json);
      if(response.success === true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
        commit("ADDRESS", response);
      }
    },
    getCountries: async ({ commit }) => {
      const response = await WishlistCart.countries();
      commit("COUNTRIES", response);
    },
    getState: async ({ commit }, id) => {
      const response = await WishlistCart.State(id);
      commit("STATEDATA", response);
    },
    getCity: async ({ commit }, id) => {
      const response = await WishlistCart.cities(id);
      commit("CITYDATA", response);
    },
    getAddressDelete: async ({ commit }, id) => {
      const response = await WishlistCart.addressDelete(id);
      if(response.success === true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
        commit("ADDRESS", response);
      }
    },
    getCheckout: async ({ commit }, json) => {
      const response = await WishlistCart.checkout(json);
      commit("CHECKOUT", response);
    },
    getCoupon: async ({ commit }, {token, json}) => {
      const response = await WishlistCart.coupon(token, json);
      commit("COUPON", response);
    },
    getOrderCode: async ({ commit },json) => {
      const response = await WishlistCart.orderCode(json);
      commit("ORDERCODE", response);
    },
    getOrderListData: async ({commit}) => {
      const response = await WishlistCart.orderListData();
      commit("ORDERLISTDATA", response);
    },
    getOrderCancel: async ({commit}, slug) => {
      const response = await WishlistCart.orderCancel(slug);
      if(response.success === true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
      }
      // commit("ORDERCANCEL", response);
    }
    // getCarts: async ({ commit }, token) => {
    //   let cart = localStorage.getItem("cart");
    //   let data = JSON.stringify({success: true, cart_items: {data: cart}, shops:{data: }})
    //   const response = await WishlistCart.carts(cart, token);
    //   console.log(response);
    // }
  },
};

export default CartPageStore;
