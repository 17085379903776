import axios from "axios";
import URL_API from "./BaseUrl";
import { setupCache } from "axios-cache-adapter";
import store from "./../store";
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});
const api = axios.create({
  adapter: cache.adapter,
});

const WishlistCartServer = {
  WishlistAdd(json) {
    let token = store.getters.isLoggedIn;
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    return api({
      url: `${URL_API}user/wishlists`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json,
    }).then(async (response) => {
      return response.data;
    });
  },
  WishlistShow() {
    let token = store.getters.isLoggedIn;
    if(token) {
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
      };
      // return api({
      //   url: `${URL_API}user/wishlists`,
      //   method: "GET",
      //   clearCacheEntry: true,
      //   headers: headersList
      // }).then(async (response) => {
      //   return response.data;
      // });
      return axios({
        method: "get",
        url: `${URL_API}user/wishlists`,
        headers: headersList
      }).then(function (response) {
        return response.data;
      });
    }
  },
  WishlistDelete(token, productId) {
    return api({
      url: `${URL_API}user/wishlists/${productId}`,
      method: "DELETE",
      clearCacheEntry: false,
      headers: { Authorization: `Bearer ${token}` },
    }).then(async (response) => {
      return response.data;
    });
  },
  addresses() {
    const token = store.getters.isLoggedIn;
    if(token) {
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
       }
      return api({
        url: `${URL_API}user/addresses`,
        method: "GET",
        clearCacheEntry: false,
        headers: {"Authorization" : `Bearer ${token}`}
      }).then(async (response) => {
        return response.data;
      });
    }
  },
  addAddresses(json) {
    const token = store.getters.isLoggedIn;
    if(token) {
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
       }
      return api({
        url: `${URL_API}user/address/create`,
        method: "POST",
        clearCacheEntry: false,
        headers: headersList,
        data: json
      }).then(async (response) => {
        return response.data;
      });
    }
  },
  updateAddresses(json) {
    const token = store.getters.isLoggedIn;
    if(token) {
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
       }
      return api({
        url: `${URL_API}user/address/update`,
        method: "POST",
        clearCacheEntry: false,
        headers: headersList,
        data: json
      }).then(async (response) => {
        return response.data;
      });
    }
  },
  addressDelete(id) {
    let token = store.getters.isLoggedIn;
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    if(token) {
      return api({
        url: `${URL_API}user/address/delete/${id}`,
        method: "GET",
        clearCacheEntry: false,
        headers: headersList
      }).then(async (response) => {
        return response.data;
      });
    }
  },
  carts(json, token) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
     }
    return api({
      url: `${URL_API}carts`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  addCarts(json, token) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    return axios({
      url: `${URL_API}carts/add`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  changeQuantity(json, token) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    return api({
      url: `${URL_API}carts/change-quantity`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  deleteFromCart(json, token) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    return api({
      url: `${URL_API}carts/destroy`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  countries() {
    return api({
      url: `${URL_API}all-countries`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  State(id) {
    return api({
      url: `${URL_API}states/${id}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  cities(id) {
    return api({
      url: `${URL_API}cities/${id}`,
      method: "GET",
      clearCacheEntry: false,
    }).then(async (response) => {
      return response.data;
    });
  },
  shippingCost(id) {
    const token = store.getters.isLoggedIn;
    if(token) {
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
      };
      return api({
        url: `${URL_API}checkout/get-shipping-cost/${id}`,
        method: "GET",
        clearCacheEntry: false,
        headers: headersList
      }).then(async (response) => {
        return response.data;
      });
    }
  },
  checkout(json) {
    let token = store.getters.isLoggedIn;
    if(token) {
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
      };
      return api({
        url: `${URL_API}checkout/order/store`,
        method: "POST",
        clearCacheEntry: false,
        headers: headersList,
        data: json
      }).then(async (response) => {
        return response.data;
      });
    }
  },
  coupon(token, json) {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    return api({
      url: `${URL_API}checkout/coupon/apply`,
      method: "POST",
      clearCacheEntry: false,
      headers: headersList,
      data: json
    }).then(async (response) => {
      return response.data;
    });
  },
  orderCode(json) {
    let token = store.getters.isLoggedIn;
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    if(token) {
      return api({
        url: `${URL_API}user/order/${json}`,
        method: "GET",
        clearCacheEntry: false,
        headers: headersList
      }).then(async (response) => {
        return response.data;
      });
    }
  },
  orderListData() {
    let token = store.getters.isLoggedIn;
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    if(token) {
      return api({
        url: `${URL_API}user/orders`,
        method: "GET",
        clearCacheEntry: false,
        headers: headersList
      }).then(async (response) => {
        return response.data;
      });
    }
  },
  orderCancel(slug) {
    let token = store.getters.isLoggedIn;
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`
    };
    if(token) {
      return api({
        url: `${URL_API}user/order/cancel/${slug}`,
        method: "GET",
        clearCacheEntry: false,
        headers: headersList
      }).then(async (response) => {
        return response.data;
      });
    }
  }
};

export default WishlistCartServer;
