<script setup>
  import { ref, defineProps, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  const props = defineProps(["router","account","order","wishlist","logout"]);
  const store = useStore();
  const primary = ref(store.getters.primaryColor);
  const whiteColor = ref(store.getters.whiteColor);
  const userInfo = computed(() => store.getters.userData);
  const userAvatar = computed(() => store.getters.userData ? store.getters.userData.avatar : "");
  const userName = computed(() => store.getters.userData ? store.getters.userData.name : "");
  const userPhone = computed(() => store.getters.userData ? store.getters.userData.phone : "");
  const userEmail = computed(() => store.getters.userData ? store.getters.userData.email : "");
  const logout = () => {
    let token = store.getters.isLoggedIn;
    if(token) {
      localStorage.removeItem("token");
      store.commit("SET_TOKEN_EMPTY")
      store.commit("UPDATECARTNULL");
      // store.commit("WISHLISTLOGOUT");
      store.dispatch("getWishlistsShow");
      props.router.push({path: "/user"});
    }
  }
  onMounted(() => {
    // store.dispatch("getUserData", store.getters.isLoggedIn).then(() => {
    //   userInfo.value = store.getters.userData.user;
    // });
    store.dispatch("getUserData", store.getters.isLoggedIn);
  })
</script>
<template>
  <div class="mb-4">
    <img
      :src="`${userAvatar ? userAvatar : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}`"
      class="img-thumbnail"
      alt="..."
    />
    <div class="list-group mt-4">
      <!-- <a
        href="#"
        class="list-group-item list-group-item-action active"
        aria-current="true"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Wallet</h5>
          <h5>{{userInfo.balance}}</h5>
        </div>
      </a> -->
      <div class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-start">
          <i class="bi bi-person me-2"></i>
          <h6 class="mb-1" style="word-break: break-all;">{{userName}}</h6>
        </div>
      </div>
      <div class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-start">
          <i class="bi bi-telephone me-2"></i>
          <h6 class="mb-1">{{userPhone}}</h6>
        </div>
      </div>
      <div class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-start">
          <i class="bi bi-envelope me-2"></i>
          <h6 class="mb-1" style="word-break: break-all;">{{userEmail}}</h6>
        </div>
      </div>
    </div>
    <b-button-group outline vertical class="btn-grp mt-3 w-100">
      <b-button class="px-3 py-2" :class="props.order == true ? 'active-bg' : ''" @click="props.router.push({path: '/order'})"><i class="bi bi-journal-bookmark"></i><h6 class="mb-1">My Order</h6></b-button>
      <b-button class="px-3 py-2" :class="props.account == true ? 'active-bg' : ''" @click="props.router.push({path: '/account'})"><i class="bi bi-person-square"></i><h6 class="mb-1">My Account</h6></b-button>
      <b-button class="px-3 py-2" :class="props.wishlist == true ? 'active-bg' : ''" @click="wishlist(props.router.push({path: '/wishlist'}))"><i class="bi bi-heart"></i><h6 class="mb-1">My Wishlist</h6></b-button>
      <b-button class="px-3 py-2" :class="props.logout == true ? 'active-bg' : ''" @click="logout()"><i class="bi bi-box-arrow-left"></i><h6 class="mb-1">Logout</h6></b-button>
    </b-button-group>
  </div>
</template>
<style lang="scss">
.btn-grp {
  .btn-secondary {
    --bs-btn-bg: v-bind(whiteColor) !important;
    --bs-btn-color: v-bind(primary) !important;
    --bs-btn-border-color: v-bind(primary) !important;
    --bs-btn-hover-bg: v-bind(primary) !important;
    --bs-btn-hover-border-color: v-bind(primary) !important;
  }
  .btn {
    text-align: left;
    .btn-content {
      width: inherit;
      i {
        float: left;
        margin-right: 10px;
      }
    }
  }
  .active-bg {
    background-color: v-bind(primary) !important;
    color: v-bind(whiteColor) !important;
  }
}
</style>
