import WishlistCartServer from "./../server/WishlistCartServer.js";
import Swal from 'sweetalert2';
import store from "./../store";
const WishlistsPageStore = {
  state: () => ({
    wishListData: null,
    wishListAdd: null,
    wishListCount: null
  }),
  getters: {
    wishListData: (state) => {
      return state.wishListData == null ? [] : state.wishListData.data;
    },
    wishListAdd: (state) => {
      return state.wishListAdd;
    },
    wishListLength: (state) => {
      return state.wishListCount;
    }
  },
  mutations: {
    WISHLISTSHOWDATA: (state, response) => {
      state.wishListData = response;
    },
    WISHLISTLOGOUT: (state) => {
      state.wishListData = null;
    },
    WISHLISTADD: (state, response) => {
      state.wishListAdd = response;
    },
    WISHLISTCOUNT: (state, res) => {
      state.wishListCount = res;
    },
  },
  actions: {
    getWishlistsAdd: async ({ commit },json) => {
      const response = await WishlistCartServer.WishlistAdd(json);
      if(response.success == true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
        store.dispatch("getWishlistsShow");
      }
      commit("WISHLISTADD", response);
    },
    getWishlistsShow: async ({ commit }) => {
      let token = store.getters.isLoggedIn;
      const response = await WishlistCartServer.WishlistShow();
      commit("WISHLISTSHOWDATA", response);
      let res = token ? response.data.length : 0;
      commit("WISHLISTCOUNT", res);
    },
    getWishlistsDelete: async ({ commit }, { token, productId }) => {
      const response = await WishlistCartServer.WishlistDelete(token, productId);
      if(response.success == true) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          toast: true,
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
        store.dispatch("getWishlistsShow");
      }
    },
  },
};
export default WishlistsPageStore;
