<template>
  <div>
    <input type="checkbox" :value="item.id" v-model="model" /> {{ item.name }}
  </div>
</template>
<script>
export default {
//   data() {
//     return {
//       firstName: "John",
//       lastName: "Doe",
//     };
//   },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
};
</script>
