import { createStore } from "vuex";
import homePageStore from "./homePageStore";
import LoginPageStore from "./LoginPageStore";
import CartPageStore from "./CartPageStore";
import WishlistsPageStore from "./WishlistsPageStore";
export default createStore({
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  modules: {
    homePageStore,
    LoginPageStore,
    CartPageStore,
    WishlistsPageStore
  },
});
