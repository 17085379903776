import AuthService from "./../server/AuthService.js";
import HomeService from "./../server/HomeService.js";
const homePageStore = {
  state: () => ({
    primaryColor: "#0080FF",
    // primaryColor: "#60c0bf",
    whiteColor: "#ffffff",
    banner: null,
    menu: null,
    productBlackFriday: null,
    categoryList: null,
    homeData: null,
    headerData: null,
    footerData: null,
    categoriesData: null,
    productList: null,
    brandList: null,
    brandProduct: null,
    offerList: null,
    productSearch: null,
    productDetails: null,
    reviews: null,
    related: null,
    productCategory: null,
    productSearchList: null,
    offers: null,
    offerdata: null,
    pageData: null
  }),
  getters: {
    homeData: (state) => {
      return state.homeData.data;
    },
    headerData: (state) => {
      return state.headerData;
    },
    footerData: (state) => {
      return state.footerData;
    },
    categoriesData: (state) => {
      return state.categoriesData;
    },
    productList: (state) => {
      return state.productList;
    },
    brandList: (state) => {
      return state.brandList;
    },
    brandProduct: (state) => {
      return state.brandProduct;
    },
    offerList: (state) => {
      return state.offerList;
    },
    productSearch: (state) => {
      return state.productSearch;
    },
    productDetails: (state) => {
      return state.productDetails;
    },
    productCategory: (state) => {
      return state.productCategory;
    },
    productSearchList: (state) => {
      return state.productSearchList;
    },
    bannerData: (state) => {
      return state.banner;
    },
    // menuData: (state) => {
    //   return state.menu;
    // },
    // productBlackFriday: (state) => {
    //   return state.productBlackFriday;
    // },
    categoryList: (state) => {
      return state.categoryList;
    },
    primaryColor: (state) => {
      return state.primaryColor;
    },
    whiteColor: (state) => {
      return state.whiteColor;
    },
    reviews: (state) => {
      return state.reviews;
    },
    related: (state) => {
      return state.related;
    },
    offers: (state) => {
      return state.offers !== null ? state.offers.data : "";
    },
    offerdata: (state) => {
      return state.offerdata !== null ? state.offerdata.data : "";
    },
    pageData: (state) => {
      return state.pageData !== null ? state.pageData.data : "";
    }
  },
  mutations: {
    HOMEDATA(state, response) {
      state.homeData = response;
    },
    HEADERDATA(state, response) {
      state.headerData = response;
    },
    FOOTERDATA(state, response) {
      state.footerData = response;
    },
    CATEGORIES(state, response) {
      state.categoriesData = response;
    },
    PRODUCTLIST(state, responce) {
      state.productList = responce;
    },
    PRODUCTLISTCATEGORY(state, responce) {
      state.productCategory = responce;
    },
    BRANDLIST(state, responce) {
      state.brandList = responce;
    },
    BRANDPRODUCT(state, responce) {
      state.brandProduct = responce;
    },
    OFFERLIST(state, responce) {
      state.offerList = responce;
    },
    PRODUCTSEARCH(state, responce) {
      state.productSearch = responce;
    },
    PRODUCTDETAILS(state, responce) {
      state.productDetails = responce;
    },
    PRODUCTSEARCHLIST(state, responce) {
      state.productSearchList = responce;
    },
    BANNER(state, response) {
      state.banner = response;
    },
    OFFERS(state, responce) {
      state.offers = responce;
    },
    OFFERDATA(state, responce) {
      state.offerdata = responce;
    },
    PAGEDATA(state, responce) {
      state.pageData = responce;
    },
    // MENULIST(state, response) {
    //   state.menu = response;
    // },
    // PRODUCTBLACKFRIDAY(state, response) {
    //   state.productBlackFriday = response;
    // },
    CATEGORYLIST(state, response) {
      state.categoryList = response;
    },
    REVIEWS(state, response) {
      state.reviews = response;
    },
    RELATED(state, response) {
      state.related = response;
    }
  },
  actions: {
    getHome: async ({ commit }, slug) => {
      const response = await HomeService.homeDataApi(slug);
      commit("HOMEDATA", response);
    },
    getHeader: async ({ commit }) => {
      const response = await HomeService.headerDataApi();
      commit("HEADERDATA", response);
    },
    getFooter: async ({ commit }) => {
      const response = await HomeService.footerDataApi();
      commit("FOOTERDATA", response);
    },
    getCategories: async ({ commit }) => {
      const response = await HomeService.categoriesDataApi();
      commit("CATEGORIES", response);
    },
    getProductListData: async ({ commit }) => {
      const responce = await HomeService.productListDataApi();
      commit("PRODUCTLIST", responce);
    },
    getProductListPage: async ({ commit },page) => {
      const responce = await HomeService.productListPageApi(page);
      commit("PRODUCTLIST", responce);
    },
    getProductListCategory: async ({ commit },slug) => {
      const responce = await HomeService.productCategoryApi(slug);
      commit("PRODUCTLISTCATEGORY", responce);
    },
    getProductSeachList: async ({ commit }, slug) => {
      const responce = await HomeService.productSearchDataApi(slug);
      commit("PRODUCTSEARCH", responce);
    },
    getSeachList: async ({ commit }, json) => {
      const responce = await HomeService.productSearch(json);
      commit("PRODUCTSEARCHLIST", responce);
    },
    getBrandListData: async ({ commit }) => {
      const responce = await HomeService.brandListDataApi();
      commit("BRANDLIST", responce);
    },
    getBrandProductData: async ({ commit }, slug) => {
      const responce = await HomeService.productBrandApi(slug);
      commit("BRANDPRODUCT", responce);
    },
    getOfferListData: async ({ commit }) => {
      const responce = await HomeService.offerListDataApi();
      commit("OFFERLIST", responce);
    },
    getDetailsData: async ({ commit }, slug) => {
      const responce = await HomeService.productDetailsApi(slug);
      commit("PRODUCTDETAILS", responce);
    },

    getbanner: async ({ commit }) => {
      const response = await AuthService.bannerData();
      commit("BANNER", response);
    },
    // getMenu: async ({ commit }) => {
    //   const response = await AuthService.menuData();
    //   commit("MENULIST", response);
    // },
    // getProductBlackFriList: async ({ commit }) => {
    //   const response = await AuthService.productBlackFriList();
    //   commit("PRODUCTBLACKFRIDAY", response);
    // },
    getCategoryList: async ({ commit }) => {
      const response = await AuthService.categoryList();
      commit("CATEGORYLIST", response);
    },
    getReviews: async ({ commit }, id) => {
      const response = await HomeService.productReviews(id);
      commit("REVIEWS", response);
    },
    getRelated: async ({ commit }, id) => {
      const response = await HomeService.productRelated(id);
      commit("RELATED", response);
    },
    getOffers: async ({ commit }) => {
      const response = await HomeService.offers();
      commit("OFFERS", response);
    },
    getOfferData: async ({ commit }, slug) => {
      const response = await HomeService.offersData(slug);
      commit("OFFERDATA", response);
    },
    getPageData: async ({ commit }, slug) => {
      const response = await HomeService.pageData(slug);
      commit("PAGEDATA", response);
    }
  },
};

export default homePageStore;
